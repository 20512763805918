/* You can add global styles to this file, and also import other style files */
@import "app/constants";
//@import '~aws-amplify-angular/theme.css';
@import "./assets/ctl-theme";
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "flexmonster/flexmonster.min.css";


* {
  font-family: 'Inter', sans-serif;
  font-weight: normal;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  display: block;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-size: 13px;
}

.mat-expansion-panel-body {
  padding: 0 !important;
  overflow: scroll;
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #333;

    th {
      background: #083176;
      padding: $size_small $size_medium;
      color: white;
      text-align: center;
      font-weight: 500;
    }

    tr:nth-child(odd) > td {
      background: #DADEE2;
      padding: $size_small;
      text-align: center;
      font-weight: 500;
    }

    td {
      padding: $size_small;
      text-align: center;
      font-weight: 500;
      border: 1px solid #333;
    }
  }
}

.mat-tooltip {
  opacity: 0.8 !important;
  color: white !important;
  background: #3b3a3a !important;
}

.modal-container {
  .mat-dialog-container {
    padding: 0 !important;
    overflow: hidden !important;
    min-width: 500px;

    .mat-form-field {
      padding-bottom: 0;
    }

    .mat-checkbox-layout {
      padding: 1rem;
    }
  }
}

app-widget-header {
  .widget-card-header {
    height: 53px;
    overflow: auto;
    .mat-card-header-text {
      margin: 0 $size_extra-small !important;
    }
    .mat-card-title {
      font-size: 16px;
    }
  }
}

//severity and priority levels colors
$grade-1: #FA5056;
$grade-2: #E8663F;
$grade-3: #E6A243;
$grade-5: #CDA3F7;
$grade-4: #1EC7B9;


//Global sizes. This is to be used anytime we want to add any custom css for sizing, spacing, etc. This should cover all needed sizes from mobile to large monitors
$size_extra-small: 4px;
$size_small: 8px;
$size_medium: 16px;
$size_large: 22px;
$size_extra-large: 32px;

.padding-all--small {
  padding: $size_small;
}

//To be used in all pages. Gives a little padding at the page level so elements aren't touching toolbar or sidenav
.layout-padding {
  padding: $size_small;
}

.gap-top {
  margin-top: $size_small;
}

.gap-bottom {
  margin-bottom: $size_small;
}

strong {
  font-weight: bold;
}

.grade-1 {
  border-left: 4px solid;
  border-color: $grade-1;
}

.grade-2 {
  border-left: 4px solid;
  border-color: $grade-2;
}

.grade-3 {
  border-left: 4px solid;
  border-color: $grade-3;
}

.grade-4 {
  border-left: 4px solid;
  border-color: $grade-4;
}

.grade-5 {
  border-left: 4px solid;
  border-color: $grade-5;
}

.text-center {
  text-align: center;
}

.no-top-margin {
  margin-top: 0;
}

.block-right {
  display: block;
  text-align: right;
}


button {
  mat-icon {
    margin: 0 $size_extra-small;
  }
}

mat-dialog-container {
  padding: 0 !important;
}

.error {
  margin-bottom: 10px;
  padding: 5px;
  color: $grade-1;
  text-align: center;
}

.ctl-panel-class {
  .mat-dialog-container {
    .dialog-wrapper {
      padding: $size_extra-large !important;
    }
  }
}


.query-builder {
  margin-left: 11px !important;
}