// global scss constant definitions
// debug border
$debug-border: 1px solid red;

// default general
$default-padding: 20px;
$default-link-hover-color: #0047bb;
//$default-text-color: #242526;

// Header default css config
$header-height: 50px;
$header-bg: black;
$header-color: #ffffff;

// side navigation default config
$side-nav-min-width: 50px;
$side-nav-max-width: 250px;

// footer default config
$footer-height: 50px;
$footer-color: #242526;

//Severity / Priority colors
$grade-1: #FA5056;
$grade-2: #E8663F;
$grade-3: #E6A243;
$grade-5: #CDA3F7;
$grade-4: #1EC7B9;

//Global sizes. This is to be used anytime we want to add any custom css for sizing, spacing, etc. This should cover all needed sizes from mobile to large monitors
$size_extra-small: 4px;
$size_small: 8px;
$size_medium: 16px;
$size_large: 22px;
$size_extra-large: 32px;

// Knowledge Base Colors
$KBWhite: white;
$KBBlack: black;
$KBWhiteBackground: whitesmoke;
$KBButton: gray;
$KBGray: lightgray;
$lumenBlue: #0075C9;

// Threat Hunting Colors
$ThreatHover: #d0e1f7;
$ThreatSelect: #b7d3f8;
$ThreatDisabled: #e1dde5;


@function contentHeight($headerHeight, $footerHeight) {
  $t: $headerHeight + $footerHeight;
  $result: calc(100% - #{$t});
  @return $result;
}

